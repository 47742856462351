<template>
	<div>
		<v-layout xs12>
			<v-flex xs4>
				<a href="https://www.agrogestor.es/plataforma-agrogestor/" target="_blank">
					<img class="md15img" src="@/assets/images/agrogestor.png" />
				</a>
			</v-flex>
			<v-flex xs4>
				<img src="@/assets/images/life_title.png"  />
			</v-flex>
			<v-flex xs4>
				<a href="http://agroasesor.prodevelop.es/" target="_blank">
					<img class="md15img" src="@/assets/images/agroasesor.png" />
				</a>
			</v-flex>
		</v-layout>
		<!-- style="margin-left:50px;margin-top:10px" -->
		<v-layout xs12>
			<v-flex xs12>
				<img class="xlimg ml2" src="@/assets/images/regadio.png" />
			</v-flex>
		</v-layout>
		<v-layout xs12 class="ml15">
			<v-flex xs12>
				<label class="bluetitle">{{ $t('home.socios') }}</label>
			</v-flex>
		</v-layout>
		<v-layout xs12 class="ml15 mt10">
			<v-flex xs12 md2 class='image-container'>
				<a href="https://intiasa.es/es/" target="_blank">
					<img src="@/assets/images/INTIA.jpg" class="img150" />
				</a>
			</v-flex>
			<v-flex xs12 md2 class='image-container'>
				<a href="http://www.itap.es/" target="_blank">
					<img src="@/assets/images/ITAP.jpg" class="img100" style="padding-top:3px" />
				</a>
			</v-flex>
			<v-flex xs12 md2 class='image-container'>
				<a href="https://neiker.eus/es/" target="_blank">
					<img src="@/assets/images/neiker.png" class="img100" />
				</a>
			</v-flex>
			<v-flex xs12 md3 class='image-container'>
				<a href="https://ifapa.junta-andalucia.es/agriculturaypesca/ifapa/web/" target="_blank">
					<img src="@/assets/images/IFAPA.png" style="width: 200px;" />
				</a>
			</v-flex>
			<v-flex xs12 md2 class='image-container'>
				<a href="http://www.irta.cat/es/centre/mas-badia/" target="_blank">
					<img src="@/assets/images/IRTA.png" style="width: 50px; padding-top:8px" />
				</a>
			</v-flex>
			<v-flex xs12 md2 class='image-container'>
				<a href="https://www.prodevelop.es/" target="_blank">
					<img src="@/assets/images/prodevelop.png" style="width: 175px;" />
				</a>
			</v-flex>
			<v-flex xs12 md2 class='image-container'>
				<a href="http://www.aemet.es/es/portada" target="_blank">
					<img src="@/assets/images/AEMET.png" style="width: 75px;" />
				</a>
			</v-flex>
		</v-layout>
		<v-layout xs12 class="mt10 ml15">
			<v-flex xs12>
				<label class="bluetitle">{{ $t('home.colaboradores') }}</label>
			</v-flex>
		</v-layout>
		<v-layout xs12 class="ml15">
			<v-flex xs12 md2>
				<img src="@/assets/images/ITACYL.png" class="img150" />
			</v-flex>
			<v-flex xs12 md2 mt10>
				<img src="@/assets/images/IMIDRA.png" class="img100" />
			</v-flex>
			<v-flex xs12 md2>
				<img src="@/assets/images/ctaex.jpg" class="img100" />
			</v-flex>
			<v-flex xs12 md2>
				<img src="@/assets/images/CITA.png" style="width: 75px;" />
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
export default {
	name: 'homepanel'
};
</script>
<style scoped>
.img150 {
	width: 150px;
}
.img100 {
	width: 100px;
}
.ml20 {
	margin-left: 20px;
}
.ml15 {
	margin-left: 15px;
}
.mt10 {
	margin-top: 10px;
}
.ml2 {
	margin-left: 2px;
}
.smimg {
	max-width: inherit;
	height: 5vh;
}
.xlimg {
	max-width: inherit;
	/*height: 45vh;*/
}
.mdimg {
	max-width: inherit;
	height: 12vh;
}
.md15img {
	max-width: inherit;
	height: 15vh;
}
.bluetitle {
	font-size: 18pt;
	color: #03a0e3;
	font-weight: bold;
}
.box {
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
}
.image-container {
  display: flex;
  justify-content: center;
}
</style>
